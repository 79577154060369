.app_referral_layout {
  height: 100vh;
  width: 100vw;
  background-image: url("../../components/asset/background.svg");
  background-position: center center;
  background-size: cover;
  animation-name: bg;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-delay: 0.5s;
  animation-timing-function: linear;
  overflow-x: hidden;
}

@keyframes bg {
  0% {
    background-image: url("../../components/asset/background.svg");
  }
  100% {
    background-image: url("../../components/asset/background2.svg");
  }
}

.carousel_box_container {
  position: absolute;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  /* background-color: #1B0E44; */
  background-position: center center;
  /* background-size: cover; */
  z-index: 1;
}

.move_left_right img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  animation: left-to-right-action 1s cubic-bezier(0.42, 0, 0.92, 1.87) forwards;
}
.move_right_left img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  animation: right-to-left-action 1s cubic-bezier(0.42, 0, 0.92, 1.87) forwards;
}

@keyframes left-to-right-action {
  0% {
    transform: translateX(-90%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes right-to-left-action {
  0% {
    transform: translateX(90%);
  }
  100% {
    transform: translateX(0%);
  }
}

/* .move_left_right {
  animation-name: img_one;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.42, 0, 0.92, 1.87);
  transform: translateX(-100%);
}

.move_right_left {
  animation-name: img_two;
  animation-duration: 5s;
  animation-delay: 5s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  transform: translateX(-100%);
} */

.carousel_image_one,
.carousel_image_two {
  width: 100%;
  height: 100%;
  max-width: 100%;
}

/* .carousel_box_container {
  max-width: 100%;
}

.carousel_box_container > img {
  width: 100%;
  height: 100%;
  position: absolute;
  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.move_left_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  animation: left-to-right-action 1s cubic-bezier(0.42, 0, 0.92, 1.87) forwards;
}
.move_right_left img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  animation: right-to-left-action 1s cubic-bezier(0.42, 0, 0.92, 1.87) forwards;
}

@keyframes left-to-right-action {
  0% {
    transform: translateX(-90%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes right-to-left-action {
  0% {
    transform: translateX(90%);
  }
  100% {
    transform: translateX(0%);
  }
} */

/* *{
    padding: 0;
    margin: 0;
} */

html {
    background-color: #F7F8F9!Important;
}

a {
    text-decoration: none;
    color: inherit;
}
a:visited {
    text-decoration: none;
    color: inherit;
}

.row {
    margin-left: 0px!important;
    margin-right: 0px!important;
}

.box-management {
    color: #1B0E44;
    background-color: #FCF1D9;
    max-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow-x: hidden;
    background-position: center center;
    background-size: cover;
    position: relative;
    /* scroll-behavior: unset; */
}

.box-bg1 {
    background-image: url('../../../components/asset/background.svg');
}
.box-bg2 {
    background-image: url('../../../components/asset/background2.svg');
}


.box-bg {
    background-image: url('../../../components/asset/background-landing.svg');
}

.app-nav {
    margin-top: 45px;
    /* width: 120px; */
    display: flex;
    justify-content: center;
}

.app-nav-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #000;
    width: 180px;
    height: 63px;
    border-radius: 16px;
    background-color: "black"
}

.app-nav-icon:hover {
    opacity: 0.5;
}

.text-area {
    font-family: Rubik;
    font-style: normal;
    color: #FFFFFF;
    margin-left: 18px;
}

.text-area .top {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}

.text-area .bottom {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
}


.display-none {
    display: none;
}

.display-block {
    display: block;
}


.carousel-box {
    position: absolute;
    width: 100%;
    height: 100vh;
    /* background-color: #1B0E44; */
    background-position: center center;
    /* background-size: cover; */
}

.move-left-right img{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100vh;
    animation: left-to-right-action 1s cubic-bezier(0.42, 0, 0.92, 1.87) forwards;
}
.move-right-left img{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100vh;
    animation: right-to-left-action 1s cubic-bezier(0.42, 0, 0.92, 1.87) forwards;
}

@keyframes left-to-right-action {
    0% {
        transform: translateX(-90%);
    }
    100% {
        transform: translateX(0%);
    }
}

@keyframes right-to-left-action {
    0% {
        transform: translateX(90%);
    }
    100% {
        transform: translateX(0%);
    }
}

.absolute-div {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%!important;
    height: 100vh;
    padding: 0px!important;
    margin: 0px!important;
}

.body-flow {
    display: flex;
    flex-direction: column;
    height: inherit;
    position: relative;
    /* background-color: yellow; */
    /* align-items: center; */
}

.central-msg {
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: Anderson Grotesk;
    font-style: normal;
    font-weight: normal;
    font-size: 60px;
    line-height: 73px;

    color: #000000;

    text-shadow: 3px 3px 1px rgba(70, 80, 229, 0.15);

}

.msg {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 150%;

    text-align: center;

    color: #000000;
}


@media (max-width: 640px) {

}
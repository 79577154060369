.referral_header_container {
  width: 100%;
  padding: 10px;
  font-family: Rubik, serif;
  height: 70px;
}

.referral_header_nav_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.referral_nav_list_container {
  height: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  list-style: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin-top: 15px;
}

@media all and (min-width: 500px) {
  .referral_header_container {
    padding: 20px 40px 20px 40px;
  }

  .referral_nav_list_container {
    gap: 20px;
  }
}

@media all and (min-width: 1024px) {
  .referral_header_container {
    padding: 20px 100px 20px 100px;
  }
}

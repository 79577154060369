#page-content-wrapper {
    background-color: #F7F8F9;
}

body {
    box-sizing: border-box;
}

.mainContainer {
    margin: 30px;
}

.modal {
    justify-content: center;
}

.modal-content {
    border: none!Important;
}
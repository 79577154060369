.bullet {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-area_ {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
}

.bheading {
    height: 26px;
    /*width: 100%;*/

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 22px!important;
    line-height: 26px;
    color: #000000;
}

.bbody {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #202020;
}
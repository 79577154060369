.app-nav {
    margin-top: 45px;
    /* width: 120px; */
    display: flex;
    justify-content: center;
}

.app-nav-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #000;
    width: 180px;
    height: 63px;
    border-radius: 16px;
    background-color: "black"
}

.app-nav-icon:hover {
    opacity: 0.5;
}




footer {
    width: 100%;
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 160%;
    color: #202020;
    /* background-color: antiquewhite; */
    bottom: 0;
    display: flex;
    justify-content: space-between!important;
    height: 83px;
}

.absolute-footer {
    position: absolute;
}

footer .footer-left {
    display: flex;font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;


    color: #000000;
}

footer .footer-left a {
    text-decoration: none;
    margin-inline: 12px;
}

footer .footer-left a:hover {
    opacity: 0.8;
}

footer .foot-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

footer .foot-right .app-icons {
    margin-top: 0px;
    width: auto;
    display: flex;
    justify-content: space-between;
}

.clickable-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.3);
    width: 30px;
    height: 30px;
    border-radius: 2px;
    background-color: "black"
}

.clickable-icon:hover {
    opacity: 0.5;
}

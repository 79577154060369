.dbullet {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
}

.dbullet:hover {
    opacity: 0.5;
}

.dtext-area {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
}

.dbheading {
    width: 70px;
    height: 18px;

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
}

.dbbody {
    width: 167px;
    height: 28px;

    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;

    color: #202020;
}
@media screen and (max-width: 768px) {
    /* html, body {
        background-color: red;
    } */
    .brand-logo {
        display: none;
    }
    .brand-logo-mobile {
        display: block;
    }
    .navbar {
        /* padding: 10px 20px 0px 20px; */
        padding: .5rem 1rem!Important;
    }
    .openNav, .navbar-brand {
        display: block!Important;
    }
}

/* The Overlay (background) */

.overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 100%;
    width: 0;
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    /* background-color: rgb(0, 0, 0); */
    /* Black fallback color */
    background-color: #F7FCFA;
    /* Black w/opacity */
    overflow-x: hidden;
    /* Disable horizontal scroll */
    transition: 0.5s;
    /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */

.overlay-content {
    position: relative;
    top: 25%;
    /* 25% from the top */
    width: 100%;
    /* 100% width */
    text-align: center;
    /* Centered text/links */
    margin-top: 30px;
    /* 30px top margin to avoid conflict with the close button on smaller screens */
}

/* The navigation links inside the overlay */

.overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    /* Display block instead of inline */
    transition: 0.3s;
    /* Transition effects on hover (color) */
}

/* When you mouse over the navigation links, change their color */

.overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
}

/* Position the close button (top right corner) */

.overlay .closeNav {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
}

.overlay a {
    font-size: 20px
}

.overlay .closeNav {
    font-size: 40px;
    top: 15px;
    right: 35px;
}

.closeNav {
    margin-right: 14px;
}

.closeNav, .openNav {
    display: none;
}

.navbar-light .navbar-toggler {
    border-color: transparent!Important;
}

.nav-sticky {
    position: fixed!Important;
    width: 100%;
}

/* 
.overlay {
    display: none;
} */
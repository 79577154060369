.navBar {
    /*width: 100%;*/
    /*background-color: rgb(138, 161, 182); */
    display: flex;
    align-items: center;
    /* height: 40px; */
    justify-content: space-between;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000!important;
    position: relative;
}


.logo-area-login {
    /* padding-left: 140px; */
    /* padding-right: 25px; */
    margin-bottom: 0px;
    margin-top: 0px;
}

ul.left-nav {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
}


ul.left-nav li {
    display: inline;
    padding: 15px;
    cursor: pointer;
}

ul.left-nav li:hover {
    opacity: 0.5;
}

ul.right-nav li {
    display: inline;
    padding: 15px;
    cursor: pointer;
}

ul.right-nav li:hover {
    opacity: 0.5;
}

#noHover:hover {
    opacity: unset;
}

ul.right-nav {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
}




.dropdown-c {
    /* background-color: yellow; */
    position: absolute;
    top: 38px;
    right: 0;
    left: 0;
    z-index: 1;
    border: 1px solid;

    background: #FFFFFF;
    width: 249px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 200px;
    /* padding-block: 31px;
    padding-left: 21px; */
  }

/* *{
    padding: 0;
    margin: 0;
} */

html {
    background-color: #F7F8F9!Important;
}

a {
    text-decoration: none;
    color: inherit;
}
a:visited {
    text-decoration: none;
    color: inherit;
}

.row {
    margin-left: 0px!important;
    margin-right: 0px!important;
}

.box-management {
    color: #1B0E44;
    background-color: #fff!important;
    max-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow-x: hidden;
    background-position: center center;
    background-size: cover;
    position: relative;
    /* scroll-behavior: unset; */
}



.top-bg {
    background-image: url('../../../components/asset/background2.svg');
    height: 480px;
    width: 100%;
    /* background-color: lightcoral; */
    background-position: center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
}

.faqs-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex: 1;
    /* position: relative; */
}

.faqs-title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-family: Anderson Grotesk;
    font-style: normal;
    font-weight: normal;
    font-size: 60px;
    line-height: 73px;

    color: #000000;

    text-shadow: 3px 3px 1px rgba(70, 80, 229, 0.15);

}

.faqs-msg {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 150%;

    text-align: left;

    color: #000000;
}



.body-flow {
    display: flex;
    flex-direction: column;
    height: inherit;
    position: relative;
    min-height: calc(100vh);
    /* background-color: yellow; */
    /* align-items: center; */
}

.central-msg {
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: Anderson Grotesk;
    font-style: normal;
    font-weight: normal;
    font-size: 60px;
    line-height: 73px;

    color: #000000;

    text-shadow: 3px 3px 1px rgba(70, 80, 229, 0.15);

}

.msg {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 150%;

    text-align: center;

    color: #000000;
}


@media (max-width: 640px) {

}
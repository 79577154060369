/* *{
    padding: 0;
    margin: 0;
} */

html {
    background-color: #F7F8F9!Important;
}

a {
    text-decoration: none;
    color: inherit;
}
a:visited {
    text-decoration: none;
    color: inherit;
}

.row {
    margin-left: 0px!important;
    margin-right: 0px!important;
}

.box-management {
    color: #1B0E44;
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.box-bg {
    background-image: url('../../../components/asset/background-landing.svg');
    background-position: center center;
    background-size: cover;
}


.first-text {
    max-width: 278px;
    /*height: 107px;*/

    font-family: Rubik;
    font-style: normal;
    font-weight: 700px;
    font-size: 38px;
    line-height: 45px;

    color: #FFFFFF;

}

.second-text {
    max-width: 460px;
    /*height: 78px;*/
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #F2F2F2;
}

.navBar {
    /*width: 100%;*/
    /*background-color: rgb(138, 161, 182); */
    display: flex;
    align-items: center;
    /* height: 40px; */
    justify-content: space-between;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    position: relative;
}

.logo-area-login {
    /* padding-left: 140px; */
    /* padding-right: 25px; */
    margin-bottom: 0px;
    margin-top: 0px;
}

ul.left-nav {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
}


ul.left-nav li {
    display: inline;
    padding: 15px;
    cursor: pointer;
}

ul.left-nav li:hover {
    opacity: 0.5;
}

ul.right-nav li {
    display: inline;
    padding: 15px;
    cursor: pointer;
}

ul.right-nav li:hover {
    opacity: 0.5;
}

#noHover:hover {
    opacity: unset;
}

ul.right-nav {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
}




.dropdown {
    /* background-color: yellow; */
    position: absolute;
    top: 28;
    right: 0;
    left: 0;
    z-index: 1;
    border: 1px solid;

    background: #FFFFFF;
    width: 249px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 282px;
    /* padding-block: 31px;
    padding-left: 21px; */
  }



.app-icons {
    margin-top: 45px;
    width: 120px;
    display: flex;
    justify-content: space-between;
}

.clickable-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.3);
    width: 30px;
    height: 30px;
    border-radius: 2px;
    background-color: "black"
}

.clickable-icon:hover {
    opacity: 0.5;
}

.welcome-message {
    /* background-color: aliceblue; */
    width: 100%;
    padding-left: 100px;
    display: flex;
    flex-direction: column;
}

.new-away-transact {
    width: 100%;
    display: flex;
    align-items: center;
}


.new-away-transact_r {
    /* width: 100%; */
    display: flex;
    align-items: center;
}

.new-away-transact .text-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.new-away-transact .heading {
    width: 100%;
    /* height: 45px; */

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 1.9rem;
    line-height: 45px;
}


.new-away-transact_r .text-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.new-away-transact_r .heading {
    width: 100%;
    /* height: 45px; */

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 1.9rem;
    line-height: 45px;
}


.right_image img {
    width: 100%;
}

.write-up {
    /*width: 100%;*/

    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 165%;
    text-align: justify;


    color: #202020;}




.payment-product {
    background-color: #000;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-block: 140px;

}

.payment-product img {
    width: 480px;

}

.payment-product .payment-des-column {
    width: 500px;
    display: flex;
    flex-direction: column;
}

.payment-product .heading {
    width: 300px;
    /*height: 45px;*/
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 45px;
    color: #FFFFFF;
}

.payment-product .details {
    /*height: 190px;*/
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #F2F2F2;
    margin-bottom: 10px;
}


.the-promise {
    background-color: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 106px;
}

.the-promise img {
    width: 442px;
    border-radius: 20px;
}

.promise-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: flex-start; */
    /*padding: 50px;*/

    /*width: 50%;*/
    height: 343px;

    background: #404040;
    border-radius: 24px 0px 0px 24px;
}


.promise-heading {
    width: 350px;
    height: 33px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;

    color: #FFFFFF;
}

.promise-body {
    /* width: 440px; */
    /* height: 130px; */
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */


    color: #F2F2F2;

}

.promise-tile{
    width: 32px;
    height: 32px;
    background: #C4C4C4;
    margin-right: 10px;
}

.testimonial {
    background-color: #E5E5E5;
    display: flex;
    /*width: 100%;*/
    /*width: 90%;*/
    /*width: calc(100%-30px)!important;*/
    padding-block: 30px;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.testimonial::-webkit-scrollbar {
    display: none;
}

.accordion {
    padding-block: 120px;
    padding-inline: 15%;
    /* width: 780px; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.accordion-head {
    /* width: 513px; */
    /* height: 45px; */
    font-family: Rubik!important;
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 38px!important;
    line-height: 45px!important;

    /* text-align: center; */

    color: #000000!important;
}

.acc-heading {
    font-family: Rubik!important;
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 16px!important;
    line-height: 19px!important;


    color: #524343;
}

.accordion-body {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #202020;
}


.accordion .footprint {
    border-radius: 20px;
    width: 100%;
    height: 420px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 40px;
}

.bg-footprint {
    background-image: url('../../../components/asset/landing-footprint.svg');
}

.footprint-head {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 45px;

    color: #FFFFFF;
}



.footprint-body {
    /*width: 479px;*/
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #F2F2F2;
}

.ussd-icon {
    width: 200px;
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 160%;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border: 5px solid rgba(255, 255, 255, 0.12); */
}

.footnotes {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
}

.foot-tile {
    display: flex;
    flex-direction: column;
}

.footnote-head{
    margin-top: 20px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    max-width: 200px;

}



.footnote-body {
    margin-top: 24px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    color: #202020;
    max-width: 200px;
}

footer {
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 160%;
    color: #202020;
}




.spantext {
    margin-left: 20px;
    height: 26px;
    /*width: 100%;*/

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 22px!important;
    line-height: 26px;
    color: #000000;
}

.xtra-bullet {
    display: flex;
    align-items: center;
    padding-block: 20px!important;
}

@media (max-width: 640px) {


    .the-promise {
/*        background-color: #fff;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;*/
        /*padding-block: 106px;*/
    }

    ul.left-nav {
        display: flex!important;
        flex-direction: column!important;
        /*margin: 0px!important;*/
        padding: 0px!important;
        position: absolute!important;
        margin-top: 20px;
        /*top: 60!important;*/
        /*padding-top: 60px;*/
        padding: 0px 50px!important;
        left: 0;

        box-shadow: 3px 3px 5px #000;
    }

    .menu-block{
        display: block;
    }

    .menu-hidden{
        display: none;
    }

    .shrink-height{
        height: 20px!important;
    }

    .btn-white {
        /*background-color: red!important;*/
        padding: 12px 12px!important;
        font-size: 12px!important;
    }

}
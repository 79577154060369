.referral_content {
  position: relative;
  height: 100vh;
  z-index: 2;
}

.referral_content_area_wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  height: calc(100% - 70px);
  padding: 20px;
}

.referral_header_text {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: Anderson Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 73px;
  color: #000000;
  text-shadow: 3px 3px 1px rgba(70, 80, 229, 0.15);
}

.referral_sub_header_text {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: #000000;
}

.app_store_btn_container {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

@media all and (min-width: 700px) {
  .referral_header_text {
    font-size: 60px;
  }
}

@media screen and (max-width: 565px) {
    .card-r {
        display: block!Important;
    }
    .card-r .col {
        margin-bottom: 10px!Important;
    }
}

@media screen and (max-width: 645px) {
    .updateAlert {
        display: block!Important;
        height: auto!Important;
    }
    .add-your-primary-ban {
        white-space: pre-line!Important;
        margin-bottom: 10px!Important;
    }
    .compTitle {
        font-size: 20px!Important;
    }
}

@media screen and (max-width: 831px) {
    .rectangle {
        width: 100%!Important;
        margin: auto!Important;
        margin-bottom: 10px!Important;
    }
    .product-row {
        display: block!Important;
    }
}
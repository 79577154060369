.google_store_btn {
  width: 140px;
  height: 63px;
  border-radius: 16px;
  background-color: black;
  outline: none;
  border: none;
  color: whitesmoke;
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 0 20px 0 20px;
  cursor: pointer;
}

.google_play_btn_small_text {
  font-size: 12px;
  text-align: left;
}

.google_play_btn_large_text {
  font-size: 11px;
  font-weight: bold;
  text-align: left;
}

@media all and (min-width: 500px) {
  .google_store_btn {
    width: 180px;
  }

  .google_play_btn_large_text {
    font-size: 16px;
  }
}

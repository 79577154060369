.referral_footer_container {
  position: absolute;
  bottom: 0px;
  padding: 20px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.footer_stay_safe_container {
  display: none;
}

.footer_term_poilicy_contact_container {
  display: flex;
  gap: 20px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
}

.footer_power_socials {
  display: flex;
  gap: 20px;
  align-items: center;
}

.footer_powered_by {
  font-weight: 400;
  font-size: 12px;
}

@media all and (min-width: 700px) {
  .referral_footer_container {
    padding: 20px 40px 20px 40px;
    flex-direction: row;
  }
  .footer_stay_safe_container {
    display: block;
  }
  .footer_powered_by {
    font-size: 14px;
  }
}

@media all and (min-width: 1024px) {
  .referral_footer_container {
    padding: 20px 100px 20px 100px;
  }
}
